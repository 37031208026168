import React from "react";
import { withStyles, TableCell, Theme } from "@material-ui/core";
import MuiTableHead, { TableHeadProps } from "@material-ui/core/TableHead";
import { gradients } from "../../Theme";
import { TableCellProps } from "@material-ui/core/TableCell";

const styles = {
  root: {
    background: gradients.header,
    border: "none",
    color: "white"
  }
};

export const TableHead = withStyles(styles)((props: TableHeadProps) => {
  return <MuiTableHead {...props} />;
});

const cellStyles = (theme: Theme) => ({
  root: {
    color: "white",
    fontWeight: 600,
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed"
  }
});

export const TableHeadCell = withStyles(cellStyles)((props: TableCellProps) => {
  return <TableCell {...props} />;
}) as React.SFC<TableCellProps>;
