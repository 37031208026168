import React, { useContext, useEffect } from "react";
import {
  Card,
  CardContent,
  TextField,
  makeStyles,
  Typography
} from "@material-ui/core";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import CenteredProgress from "../common/CenteredProgress";
import RegenerateKeys from "./RegenerateKeys";
import { RouteComponentProps } from "@reach/router";
import PageTitleBar from "../common/header/PageTitleBar";
import GradientCardHeader from "../common/GradientCardHeader";
import PaddedLayout from "../common/PaddedLayout";
import { SecretField } from "../common/SecretField";
import { DatasetContext } from "../auth/DatasetContext";
import { AppById } from "../../graphql/AppById";

const query = gql`
  query AppById($id: ID!) {
    appById(id: $id) {
      id
      clientId
      clientSecret
      name
    }
  }
`;

const useStyles = makeStyles(theme => ({
  breadcrumb: {
    marginRight: theme.spacing(1) / 2,
    marginLeft: theme.spacing(1) / 2,
    display: "inline-block",
    "&:visited": {
      color: "#0000ff"
    }
  },
  appDetails: {
    marginBottom: theme.spacing(4)
  },
  header: {
    paddingBottom: theme.spacing(1)
  }
}));

interface RouteProps {
  appId: string;
}

interface Props extends RouteComponentProps<RouteProps> {}

const ViewApp: React.FC<Props> = props => {
  const { appId } = props;
  const classes = useStyles(props);
  const { sandboxDataset } = useContext(DatasetContext);
  const { loading, data, error, refetch } = useQuery<AppById>(query, {
    variables: { id: appId }
  });

  useEffect(() => {
    // tslint:disable-next-line: no-floating-promises
    refetch();
  }, [sandboxDataset]);

  if (loading) {
    return <CenteredProgress />;
  }

  if (error) {
    console.log("error", JSON.stringify(error));
  }

  const app = data ? data.appById : null;

  return (
    <>
      <PageTitleBar title="App Details" />
      <PaddedLayout>
        {!app ? (
          <Card>
            <CardContent>
              <Typography>
                We couldn't find the app you were looking for in{" "}
                {sandboxDataset ? "Sandbox" : "Live"} mode.
              </Typography>
            </CardContent>
          </Card>
        ) : (
          <Card className={classes.appDetails}>
            <GradientCardHeader
              title={data.appById.name}
              action={
                data.appById.clientSecret && <RegenerateKeys appUuid={appId} />
              }
              className={classes.header}
            />
            <CardContent>
              <TextField
                fullWidth
                label="Client ID"
                value={data.appById.clientId}
                margin="normal"
              />
              {data.appById.clientSecret && (
                <SecretField
                  fullWidth
                  label="Client Secret"
                  value={data.appById.clientSecret}
                  margin="normal"
                />
              )}
            </CardContent>
          </Card>
        )}
      </PaddedLayout>
    </>
  );
};

export default ViewApp;
