import React from "react";
import { Router } from "@reach/router";
import PrivateRoute from "../components/auth/PrivateRoute";
import ListApps from "../components/apps/ListApps";
import CreateApp from "../components/apps/CreateApp";
import ViewApp from "../components/apps/ViewApp";

const Apps = () => (
  <React.Fragment>
    <Router>
      <PrivateRoute path="/apps/create" component={CreateApp} />
      <PrivateRoute path="/apps/:appId" component={ViewApp} />
      <PrivateRoute path="/apps" component={ListApps} />
    </Router>
  </React.Fragment>
);

export default Apps;
