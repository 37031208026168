import React from "react";
import {
  Button,
  CardHeader,
  createStyles,
  Theme,
  withStyles,
  WithStyles
} from "@material-ui/core";
import { CardHeaderProps } from "@material-ui/core/CardHeader";
import { gradients } from "../../Theme";
import { ButtonProps } from "@material-ui/core/Button";
import { LinkButton, LinkButtonProps } from "./LinkButton";

const styles = (theme: Theme) =>
  createStyles({
    root: {
      background: gradients.header,
      color: theme.palette.common.white,
      paddingBottom: 0,
      paddingTop: theme.spacing(1),
      textAlign: "left"
    },
    typography: {
      fontSize: 18,
      fontWeight: 500
    },
    action: {
      marginRight: 0,
      marginTop: 0
    }
  });

const Header = (props: CardHeaderProps & WithStyles<typeof styles>) => {
  const { classes, ...headerProps } = props;
  return (
    <CardHeader
      classes={{
        root: classes.root,
        action: classes.action
      }}
      {...headerProps}
      titleTypographyProps={{
        color: "inherit",
        classes: { root: props.classes.typography }
      }}
    />
  );
};

export default withStyles(styles)(Header) as React.StatelessComponent<
  CardHeaderProps
>;

export const HeaderButton = React.forwardRef((props: ButtonProps, ref: any) => {
  return (
    <Button
      {...props}
      color="inherit"
      size="small"
      variant="outlined"
      ref={ref}
    />
  );
}) as React.FunctionComponent<ButtonProps>;

export const LinkHeaderButton = React.forwardRef(
  (props: LinkButtonProps, ref: any) => (
    <LinkButton
      {...props}
      color="inherit"
      size="small"
      variant="outlined"
      ref={ref}
    />
  )
);
