import * as React from "react";
import {
  Card,
  createStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Theme,
  WithStyles,
  withStyles
} from "@material-ui/core";
import gql from "graphql-tag";
import { graphql, ChildProps } from "react-apollo";
import { getN } from "../../utils/typesafe";
import CenteredProgress from "../common/CenteredProgress";
import EmptyState from "../common/EmptyState";
import { RouteComponentProps } from "@reach/router";
import { Link, navigate } from "gatsby";
import PageTitleBar from "../common/header/PageTitleBar";
import GradientCardHeader, {
  LinkHeaderButton
} from "../common/GradientCardHeader";
import { TableHead, TableHeadCell } from "../common/GradientTableHeader";
import PaddedLayout from "../common/PaddedLayout";
import moment from "moment";
import { MyApps } from "../../graphql/MyApps";
import { WithDataset, withDataset } from "../auth/withDataset";

export const MY_APPS_QUERY = gql`
  query MyApps {
    apps {
      items {
        id
        clientId
        name
        type
        created
        generated
      }
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    link: {
      "&:visited": {
        color: "#0000FF"
      }
    },
    appRow: {
      cursor: "pointer"
    }
  });

interface Props
  extends WithStyles<typeof styles>,
    RouteComponentProps<{}>,
    ChildProps<{}, MyApps>,
    WithDataset {}

class ListApps extends React.Component<Props> {
  componentDidUpdate(prevProps: Props) {
    if (prevProps.sandboxDataset !== this.props.sandboxDataset) {
      // tslint:disable-next-line: no-floating-promises
      this.props.data.refetch();
    }
  }

  render() {
    const { classes } = this.props;
    const { loading, apps } = this.props.data;

    const createApp = (
      <LinkHeaderButton to="/apps/create">Create App</LinkHeaderButton>
    );

    return (
      <React.Fragment>
        <PageTitleBar title="My Apps" />

        <PaddedLayout>
          <React.Fragment>
            <Card>
              <GradientCardHeader title="API Keys" action={createApp} />
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeadCell>App Name</TableHeadCell>
                    <TableHeadCell>App Type</TableHeadCell>
                    <TableHeadCell>Client Id</TableHeadCell>
                    <TableHeadCell>Created</TableHeadCell>
                    <TableHeadCell>Key generated</TableHeadCell>
                  </TableRow>
                </TableHead>
                {apps && (
                  <TableBody>
                    {apps.items.map((app: any) => (
                      <TableRow
                        key={app.id}
                        hover
                        className={classes.appRow}
                        onClick={() => this.openApp(app.id)}
                      >
                        <TableCell>{app.name}</TableCell>
                        <TableCell>{app.type}</TableCell>
                        <TableCell>{app.clientId}</TableCell>
                        <TableCell
                          title={
                            app.created &&
                            moment(app.created).format("hh:mma DD/MM/YYYY")
                          }
                        >
                          {app.created && moment(app.created).fromNow()}
                        </TableCell>
                        {app.generated && (
                          <TableCell
                            title={
                              app.generated &&
                              moment(app.generated).format("hh:mma DD/MM/YYYY")
                            }
                          >
                            {app.generated && moment(app.generated).fromNow()}
                          </TableCell>
                        )}
                        {!app.generated && <TableCell>n/a</TableCell>}
                      </TableRow>
                    ))}
                  </TableBody>
                )}
              </Table>
              {!loading &&
                getN(apps, "items", "length") === 0 &&
                this.blankState()}
              {loading && !apps && <CenteredProgress />}
            </Card>
          </React.Fragment>
        </PaddedLayout>
      </React.Fragment>
    );
  }

  private blankState = () => {
    return (
      <EmptyState
        text={
          <React.Fragment>
            No apps created yet.{" "}
            <Link to="/apps/create" className={this.props.classes.link}>
              Create an app
            </Link>{" "}
            to get started
          </React.Fragment>
        }
      />
    );
  };

  private openApp = (appId: string) => {
    navigate(`/apps/${appId}`);
  };
}

export default withDataset(
  graphql<{}, MyApps>(MY_APPS_QUERY)(withStyles(styles)(ListApps))
);
