import React, { Component } from "react";
import FocusedLayout from "../common/FocusedLayout";
import Form from "../common/Form";
import {
  Card,
  CardContent,
  Typography,
  WithStyles,
  Theme,
  Button,
  CardActions,
  withStyles,
  MenuItem
} from "@material-ui/core";
import { Mutation } from "react-apollo";
import gql from "graphql-tag";
import { MY_APPS_QUERY } from "./ListApps";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby-link";
import PageTitleBar from "../common/header/PageTitleBar";
import { CreateApp, CreateAppVariables } from "../../graphql/CreateApp";
import { Field } from "react-final-form";
import Input from "../common/TextField";
import { compose, maxLength, required } from "../../utils/validators";

const styles = (theme: Theme) => ({
  cardContent: {
    minHeight: 200
  },
  cardActions: {
    display: "flex",
    flexDirection: "row-reverse" as "row-reverse"
  }
});

interface Props extends RouteComponentProps<{}>, WithStyles<typeof styles> {}

const mutation = gql`
  mutation CreateApp($name: String!, $type: String!) {
    createApp(name: $name, type: $type) {
      id
      clientId
      clientSecret
    }
  }
`;

export class CreateAppView extends Component<Props> {
  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <PageTitleBar title="Create an App" />
        <FocusedLayout>
          <Mutation<CreateApp, CreateAppVariables>
            mutation={mutation}
            onCompleted={this.gotoDashboard}
            refetchQueries={[{ query: MY_APPS_QUERY }]}
            awaitRefetchQueries
          >
            {(createApp, { loading }) => (
              <Form
                onSubmit={(data: any) =>
                  createApp({
                    variables: { name: data.appName, type: data.appType }
                  })
                }
                successMessage="Created app"
              >
                {({ handleSubmit, submitting }) => (
                  <form onSubmit={handleSubmit}>
                    <Card>
                      <CardContent className={classes.cardContent}>
                        <Typography paragraph>
                          Before you can get started using the API you will need
                          to create a new application.
                        </Typography>
                        <Field
                          autoFocus
                          name="appName"
                          component={Input}
                          label="App Name"
                          placeholder="Awesome App"
                          fullWidth
                          validate={compose(
                            required("App name is required"),
                            maxLength(
                              40,
                              "App name can be at most 40 characters"
                            )
                          )}
                        />
                        <Field
                          name="appType"
                          component={Input}
                          label="App Type"
                          fullWidth
                          select
                          style={{ marginTop: 16, textAlign: "left" }}
                          validate={compose(required("App type is required"))}
                        >
                          <MenuItem value="CustomerFacing">
                            Customer facing
                          </MenuItem>
                          <MenuItem value="Backend">Backend</MenuItem>
                        </Field>
                      </CardContent>
                      <CardActions className={classes.cardActions}>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting || loading}
                        >
                          Create my App!
                        </Button>
                      </CardActions>
                    </Card>
                  </form>
                )}
              </Form>
            )}
          </Mutation>
        </FocusedLayout>
      </React.Fragment>
    );
  }

  gotoDashboard = (data: CreateApp) => {
    navigate(`/apps/${data.createApp.id}`);
  };
}

export default withStyles(styles)(CreateAppView);
