import React from "react";
import { Theme, WithStyles, withStyles, Typography } from "@material-ui/core";

const styles = (theme: Theme) => ({
  root: {
    width: "100%",
    textAlign: "center" as "center",
    padding: theme.spacing(4),
    opacity: 0.5
  }
});

const EmptyState: React.SFC<
  WithStyles<typeof styles> & { text: string | JSX.Element }
> = ({ classes, text }) => {
  return (
    <div className={classes.root}>
      <Typography>{text}</Typography>
    </div>
  );
};

export default withStyles(styles)(EmptyState);
