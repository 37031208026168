import React, { Component } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  DialogContent
} from "@material-ui/core";
import { Mutation } from "react-apollo";
import { showMessage } from "../common/Toast";
import gql from "graphql-tag";
import Warning from "../common/Warning";
import { HeaderButton } from "../common/GradientCardHeader";

interface State {
  visible: boolean;
}

interface Props {
  appUuid: string;
}

const mutation = gql`
  mutation RegenerateKeys($appUuid: ID!) {
    regenerateKeys(appUuid: $appUuid) {
      id
      clientId
      clientSecret
    }
  }
`;

export class RegenerateKeys extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { visible: false };
  }

  render() {
    const { appUuid } = this.props;
    const { visible } = this.state;

    return (
      <React.Fragment>
        <HeaderButton onClick={this.showPrompt}>Regenerate</HeaderButton>

        <Mutation mutation={mutation}>
          {(mutationFn, { loading }) => {
            const regenerateKeys = async () => {
              await mutationFn({ variables: { appUuid } });
              showMessage("App regenerated");
              this.closePrompt();
            };

            return (
              <Warning>
                <Dialog open={visible} onClose={this.closePrompt}>
                  <DialogTitle>Regenerate App</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      You are about to create a new client ID and secret. You
                      will need to update any clients that are using this app.
                      Your old credentials will stop working immediately. Are
                      you sure you want to do this?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.closePrompt}>Cancel</Button>
                    <Button
                      onClick={regenerateKeys}
                      disabled={loading}
                      color="primary"
                    >
                      Regenerate
                    </Button>
                  </DialogActions>
                </Dialog>
              </Warning>
            );
          }}
        </Mutation>
      </React.Fragment>
    );
  }

  showPrompt = () => {
    this.setState({ visible: true });
  };

  closePrompt = () => {
    this.setState({ visible: false });
  };
}

export default RegenerateKeys;
